import React from "react";
import moduleStyle from "./subscriptionTransactions.module.css";
import {
  category_statuses,
  form_data,
  STATUS_CLASSES,
  TRANSACTION_STATUS_LABELS,
  transaction_statuses,
} from "./subscriptionTransactions.constants";
import moment from "moment";
import { DISPLAY_DATE_YEAR_TIME_FORMAT } from "constants/dateTime";
import DownloadIcon from "@material-ui/icons/GetApp";
import { getUserCurrencySymbol } from "utils/AuthUtil";
import { is_empty } from "utils/validations";
import { pluralise } from "utils/Utils";
import { FILTER_TYPES } from "common/Components/ExlyTable/ExlyTableFilters/filtersConstants";
import { ActionsColumn } from "features/MySubscriptions/modules/SubscriptionTransactions/modules/ActionsColumn/ActionsColumn";
import { StatusField } from "features/MySubscriptions/modules/SubscriptionsDetailsModal/modules/StatusField/StatusField";

const TransactionStatusField = (record) => {
  const {
    [form_data.status.key]: status,
    [form_data.status_info_icon.key]: tooltip,
  } = record;

  return (
    <StatusField
      label={TRANSACTION_STATUS_LABELS[status]}
      tooltip={tooltip}
      classes={{ label: STATUS_CLASSES[status] }}
    />
  );
};

const AmountField = (record) => {
  return (
    <div>
      {getUserCurrencySymbol()}&nbsp;{record[form_data.amount.key]}
    </div>
  );
};

const TitleField = ({ record, setShowMoreData }) => {
  let title = record[form_data.name.key];
  if (record?.type === category_statuses.add_on.value) {
    //
    if (!is_empty(record?.features)) {
      const [firstFeature, ...rest] = record.features;
      title = (
        <div>
          {firstFeature?.name}

          {!is_empty(rest) ? (
            <>
              <span> + </span>
              <u onClick={() => setShowMoreData(record)} className="pointer">
                {rest.length} {pluralise("other", rest.length)}
              </u>
            </>
          ) : (
            ""
          )}
        </div>
      );
    }
  }
  return (
    <div className="d-flex align-items-center">
      {
        Object.values(category_statuses).find(
          (item) => item.value === record[form_data.type.key]
        ).icon
      }
      <span className={`${moduleStyle.title}`}>{title}</span>
    </div>
  );
};

const CategoryField = (record) => {
  return Object.values(category_statuses).find(
    (item) => item.value === record[form_data.type.key]
  ).label;
};

export const InvoiceField = (record) => {
  const invoice_url = record?.[form_data.invoice_url.key];
  return (
    <div
      className={`d-flex align-items-center ${moduleStyle.invoiceContainer} pointer`}
    >
      <DownloadIcon className={`mt-1`} />
      <div onClick={() => invoice_url && window.open(invoice_url)}>
        <span className="underline">Invoice</span>
      </div>
    </div>
  );
};

export const getSubscriptionTransactionsTableConfig = ({
  setShowMoreData,
  onCancelAutoDebit,
}) => [
  {
    field: form_data.name.key,
    headerName: form_data.name.label,
    emptyField: "N/A",
    sortable: false,
    renderColumn: (record) => (
      <TitleField record={record} setShowMoreData={setShowMoreData} />
    ),
    fixed: "left",
    valueFormatter: (record) => (
      <TitleField record={record} setShowMoreData={setShowMoreData} />
    ),
  },
  {
    field: form_data.type.key,
    headerName: form_data.type.label,
    emptyField: "N/A",
    sortable: false,
    valueFormatter: CategoryField,
  },
  {
    field: form_data.date.key,
    headerName: form_data.date.label,
    emptyField: "N/A",
    sortable: false,
    valueFormatter: (record) =>
      moment(record[form_data.date.key]).format(DISPLAY_DATE_YEAR_TIME_FORMAT),
  },
  {
    field: form_data.status.key,
    headerName: form_data.status.label,
    emptyField: "N/A",
    sortable: false,
    valueFormatter: TransactionStatusField,
  },
  {
    field: form_data.amount.key,
    headerName: form_data.amount.label,
    emptyField: "N/A",
    sortable: false,
    valueFormatter: AmountField,
  },
  {
    field: form_data.invoice_url.key,
    headerName: form_data.invoice_url.label,
    emptyField: "",
    hidden: true,
    sortable: false,
    valueFormatter: (record) => (
      <ActionsColumn record={record} onCancelAutoDebit={onCancelAutoDebit} />
    ),
  },
];

export const tabFilterConfig = [
  {
    label: category_statuses.plan_purchase.label,
    source: form_data?.type?.key,
    filterValue: category_statuses.plan_purchase.value,
    // value: "reason-type_21",
  },
  {
    label: category_statuses.add_on.label,
    source: form_data?.type?.key,
    filterValue: category_statuses.add_on.value,
    // value: "reason-type_21",
  },
];

export const getFilterConfig = () => {
  return [
    {
      source: form_data.status.key,
      placeholder: form_data.status.label,
      options: Object.values(transaction_statuses),
      type: FILTER_TYPES.dropdown,
      disableContains: true,
    },
    {
      source: form_data.date.start_date.key,
      placeholder: form_data.date.start_date.label,
      type: FILTER_TYPES.date,
    },
    {
      source: form_data.date.end_date.key,
      placeholder: form_data.date.end_date.label,
      type: FILTER_TYPES.date,
    },
  ];
};

export const tableChipsFormatter = (filter, value) => {
  switch (filter) {
    case form_data.status.key: {
      return `${form_data.status.label}: ${
        Object.values(transaction_statuses).find((item) => item.value === value)
          .label
      }`;
    }
    case form_data.date.start_date.key: {
      return `${form_data.date.start_date.label}: ${value}`;
    }
    case form_data.date.end_date.key: {
      return `${form_data.date.end_date.label}: ${value}`;
    }
    case form_data.type.key: {
      return `${form_data.type.label}: ${
        Object.values(category_statuses).find((item) => item.value === value)
          .label
      }`;
    }
    default:
      return undefined;
  }
};
