import IconButton from "@material-ui/core/IconButton";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import Historyicon from "@material-ui/icons/History";
import { Button, Card } from "@my-scoot/component-library-legacy";
import { app_pathnames, app_route_keys } from "constants/urlPaths";
import { is_empty } from "features/Common/utils/common.utils";
import { FEATURE_CREDITS_API_KEYS } from "features/MySubscriptions/constants/MySubscriptions.constants";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import React from "react";
import { getUserCurrencySymbol } from "utils/AuthUtil";
import { useCart } from "../../hooks/useCart";
import { CreditsProgress } from "./CreditsProgress";
import moduleStyle from "./featureCreditItem.module.css";

export const FeatureCreditItem = withComponentLibraryTheme(
  ({ record, onCreditPurchase, onCreditEdit }) => {
    const { getItemQuantityInCart, handleGoToCart } = useCart();
    const {
      [FEATURE_CREDITS_API_KEYS.credit_purchase_plan]: {
        [FEATURE_CREDITS_API_KEYS.units]: units,
      },
    } = record;
    const quantityInCart = getItemQuantityInCart(record?.feature_key);
    const unitsPerQuantity = units || 1;
    const totalCredits = unitsPerQuantity * quantityInCart;

    const purchasePlan = React.useMemo(() => {
      const credit_purchase_plan = record?.credit_purchase_plan;
      if (is_empty(credit_purchase_plan)) return {};
      return {
        price: `${getUserCurrencySymbol()}${credit_purchase_plan.price}`,
        quantity: `${credit_purchase_plan.units} ${credit_purchase_plan.unit_name}`,
      };
    }, []);

    const handleLedger = () => {
      window.location.href = `${window.location.origin}/#${app_pathnames[
        app_route_keys.ledger
      ](record?.feature_key)}`;
    };

    const handleOpenCreditsPurchaseModal = () => {
      onCreditPurchase(record);
    };

    const handleOpenCreditsEditModal = () => {
      onCreditEdit(record);
    };

    return (
      <Card className={`${moduleStyle.container} py-3 px-2 d-flex flex-column`}>
        <div className={`d-flex justify-content-between align-items-center`}>
          <div className={`${moduleStyle.name}`}>{record?.feature_name}</div>
          <div
            onClick={handleLedger}
            className={`${moduleStyle.history} pointer`}
          >
            <Historyicon className={`${moduleStyle.history_icon}`} /> History
          </div>
        </div>

        <CreditsProgress record={record} />

        {!is_empty(purchasePlan) && (
          <div className={`d-flex justify-content-between align-items-center`}>
            {totalCredits ? (
              <div>
                <div className="tw-text-[#272522] tw-text-sm tw-leading-[normal] tw-opacity-50 tw-mb-1">
                  In Cart:
                </div>
                <div>
                  <span className="tw-text-base tw-leading-[normal] tw-font-medium">
                    {totalCredits} Credits{" "}
                    <IconButton
                      className="!tw-p-0 !tw-opacity-50 !tw-mt-[-2px]"
                      onClick={handleOpenCreditsEditModal}
                    >
                      <EditOutlinedIcon className="!tw-text-sm !text-[#1C1B1F]" />
                    </IconButton>
                  </span>
                </div>
              </div>
            ) : (
              <div className="d-flex align-items-center">
                <span className={`${moduleStyle.priceText}`}>
                  {purchasePlan?.price}
                </span>
                &nbsp;/&nbsp;
                <span className={`${moduleStyle.quantityText} line_max_1`}>
                  {purchasePlan?.quantity}
                </span>
              </div>
            )}

            {!is_empty(totalCredits) ? (
              <Button
                color="success"
                className="!tw-h-[33px] !tw-rounded-[5px]"
                onClick={handleGoToCart}
              >
                <CheckRoundedIcon className="!tw-mr-1" /> Added in Cart
              </Button>
            ) : (
              <Button
                onClick={handleOpenCreditsPurchaseModal}
                size="thin"
                color="primary"
              >
                Buy more limit
              </Button>
            )}
          </div>
        )}
      </Card>
    );
  }
);
