import ListAltIcon from "@material-ui/icons/ListAlt";
import { Button } from "@my-scoot/component-library-legacy";
import ExlyTable from "common/Components/ExlyTable";
import { Header } from "common/Components/Header";
import { app_route_keys } from "constants/urlPaths";
import { CartStatusBar } from "features/Cart/modules/CartStatusBar/CartStatusBar";
import { LazyLoadWrapper } from "features/Common/LazyLoadWrapper/LazyLoadWrapper";
import { useIsDesktop } from "features/Common/modules/Viewport/utils/Viewport.utils";
import { is_empty } from "features/Common/utils/common.utils";
import { getRAUrlParams } from "features/Common/utils/url.utils";
import { FEATURE_CREDITS_API_KEYS } from "features/MySubscriptions/constants/MySubscriptions.constants";
import {
  CreditsPurchaseModal,
  CreditsUpdateModal,
} from "features/MySubscriptions/constants/MySubscriptions.lazy";
import { ActivePlanSection } from "features/MySubscriptions/modules/ActivePlanSection/ActivePlanSection";
import { LicensesSection } from "features/MySubscriptions/modules/LicensesSection/LicensesSection";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import useAppLayoutControl from "hooks/useAppLayoutControl";
import React from "react";
import { PaymentStatusModal } from "utils/Payments/PaymentStatusModal";
import { navigateTo } from "utils/urlUtils";
import { FeatureCreditItem } from "../../components/FeatureCreditItem";
import { useCart } from "../../hooks/useCart";
import { usePayment } from "../../hooks/usePayment";
import { MobileActions } from "./MobileActions";

export const MySubscriptions = withComponentLibraryTheme((props) => {
  const isDesktop = useIsDesktop();
  useAppLayoutControl({ control: { bottomNav: !isDesktop } });
  const { getOrderStatus } = usePayment();
  const { getCartItemsCount } = useCart();
  const cartCount = getCartItemsCount();

  const [payment_status, set_payment_status] = React.useState(null);
  const [purchaseCreditsModalState, setPurchaseCreditsModalState] =
    React.useState({
      open: false,
      record: null,
    });
  const [editCreditsModalState, setEditCreditsModalState] = React.useState({
    open: false,
    record: null,
  });

  React.useEffect(() => {
    (async () => {
      const orderId = getRAUrlParams()?.get("order");
      if (!is_empty(orderId)) {
        const orderData = await getOrderStatus(orderId);
        set_payment_status(orderData?.status);
      }
    })();
  }, []);

  const handleOpenPurchaseCreditsModal = (record) => {
    setPurchaseCreditsModalState({ open: true, record });
  };

  const handleClosePurchaseCreditsModal = () => {
    setPurchaseCreditsModalState({ open: false, record: null });
  };

  const handleOpenEditCreditsModal = (record) => {
    const {
      [FEATURE_CREDITS_API_KEYS.feature_key]: featureKey,
      [FEATURE_CREDITS_API_KEYS.credit_purchase_plan]: {
        [FEATURE_CREDITS_API_KEYS.price]: price,
        [FEATURE_CREDITS_API_KEYS.units]: units,
        [FEATURE_CREDITS_API_KEYS.credit_plan_id]: creditPlanId,
      },
    } = record;

    setEditCreditsModalState({
      open: true,
      record: {
        [FEATURE_CREDITS_API_KEYS.feature_key]: featureKey,
        [FEATURE_CREDITS_API_KEYS.price]: price,
        [FEATURE_CREDITS_API_KEYS.units]: units,
        [FEATURE_CREDITS_API_KEYS.credit_plan_id]: creditPlanId,
      },
    });
  };

  const handleCloseEditCreditsModal = () => {
    setEditCreditsModalState({ open: false, record: null });
  };

  return (
    <>
      <Header
        onBackClick={() => (window.location.href = "")}
        title="My Subscriptions"
        classNames={{
          container:
            "tw-px-6 max-md:tw-px-4 tw-min-h-[75.23px] max-md:tw-min-h-0 max-md:tw-bg-[#FCFBFE] max-md:tw-shadow-[0px_7px_10px_0px_rgba(209,210,214,0.30)] max-md:!tw-border-none",
        }}
        right={
          <div className="max-md:tw-fixed max-md:tw-inset-[auto_0_52px_0] max-md:tw-bg-white max-md:tw-border-t-[1px] max-md:tw-border-solid max-md:tw-border-0 max-md:tw-border-[#D9D9D9]">
            <Button
              onClick={() =>
                navigateTo(app_route_keys.subscription_transactions)
              }
              color="link"
              variant="text"
              disableTouchRipple
              startIcon={<ListAltIcon className="tw-text-lg" />}
              className="max-md:!tw-w-full"
            >
              See transactions
            </Button>
          </div>
        }
      />
      <div className="tw-p-6 max-md:tw-p-4 max-md:tw-pb-16">
        {isDesktop && <CartStatusBar />}

        <ActivePlanSection />

        <div className="tw-my-6 tw-w-full tw-h-[1px] tw-bg-[#D9D9D9] max-md:tw-hidden" />

        <div className="max-md:tw-mt-6">
          <LicensesSection />
        </div>

        <div className="tw-text-[#272522] tw-text-xl tw-font-medium tw-leading-[normal] tw-mb-5 tw-mt-4">
          Your available credits
        </div>

        <ExlyTable
          ra_props={{ ...props }}
          noExportButton
          layoutProps={{
            showFixedBars: true,
            noShadow: true,
            paddingBottom: "32px",
            paddingMobile: "0px",
          }}
          primaryKey="id"
          gridView
          GridComponent={({ record }) =>
            FeatureCreditItem({
              record,
              onCreditPurchase: handleOpenPurchaseCreditsModal,
              onCreditEdit: handleOpenEditCreditsModal,
            })
          }
        />
      </div>

      {!is_empty(payment_status) && (
        <PaymentStatusModal
          payment_status={payment_status}
          set_payment_status={set_payment_status}
        />
      )}
      {!is_empty(cartCount) && <MobileActions />}

      {purchaseCreditsModalState.open && (
        <LazyLoadWrapper loadingCondition={purchaseCreditsModalState.open}>
          <CreditsPurchaseModal
            open={purchaseCreditsModalState.open}
            onClose={handleClosePurchaseCreditsModal}
            record={purchaseCreditsModalState.record}
          />
        </LazyLoadWrapper>
      )}

      {editCreditsModalState.open && (
        <LazyLoadWrapper loadingCondition={editCreditsModalState.open}>
          <CreditsUpdateModal
            open={editCreditsModalState.open}
            onClose={handleCloseEditCreditsModal}
            record={editCreditsModalState.record}
          />
        </LazyLoadWrapper>
      )}
    </>
  );
});
