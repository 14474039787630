import { useDispatch, useSelector } from "react-redux";
import {
  EXLY_ACCOUNT__SET_CART,
  EXLY_ACCOUNT__UPDATE_CART,
} from "../redux/actions";
import { change_states } from "constants/recordedContent";
import { notification_color_keys, useNotifications } from "utils/hooks";
import { dataProvider } from "data";
import { UPDATE_CART_API } from "../account.apis";
import { apiMethods } from "data/api.constants";
import { navigateTo } from "utils/urlUtils";
import { app_route_keys } from "constants/urlPaths";
import { logError } from "utils/error";
import { is_empty } from "features/Common/utils/common.utils";

export const useCart = () => {
  const { notify } = useNotifications();
  const cart = useSelector((state) => state.myAccount.cart);
  const dispatch = useDispatch();

  const isProcessing = (feature_key) =>
    cart[feature_key] ? cart[feature_key]?.processing : false;

  const getItemQuantityInCart = (feature_key) =>
    cart[feature_key] ? cart[feature_key]?.quantity : 0;

  const getCartItemsCount = () => {
    let sum = 0;
    Object.values(cart).filter((item) => {
      if (item && item.change_state !== change_states.deleted) {
        sum += item.quantity || 0;
      }
    });
    return sum;
  };

  const addItemToCart = (item) => {
    if (is_empty(item?.feature_key))
      throw new Error("Invalid item in addItemToCart");

    const { feature_key } = item;
    if (isProcessing(feature_key)) return;

    const quantity = item.quantity || getItemQuantityInCart(feature_key) + 1;
    const change_state = cart[feature_key]?.uuid
      ? change_states.updated
      : change_states.added;

    const payload = {
      ...cart,
      [feature_key]: {
        ...item,
        processing: true,
        quantity,
        change_state,
      },
    };
    return updateCart({ cartItem: payload[feature_key] });
  };

  const removeItemFromCart = (item) => {
    if (
      is_empty(item?.feature_key) ||
      is_empty(getItemQuantityInCart(item?.feature_key))
    )
      throw new Error("Invalid item in removeItemFromCart");

    const { feature_key } = item;
    if (isProcessing(feature_key)) return;
    const quantity = getItemQuantityInCart(feature_key) - 1;

    // if quantity is 0, remove from redux also
    if (is_empty(quantity)) {
      return deleteItemFromCart(feature_key);
    }

    const change_state = cart[feature_key]?.uuid
      ? is_empty(quantity)
        ? change_states.deleted
        : change_states.updated
      : cart[feature_key]?.change_state;

    const payload = {
      ...cart,
      [feature_key]: {
        ...item,
        processing: true,
        quantity,
        change_state,
      },
    };
    dispatch({
      type: EXLY_ACCOUNT__SET_CART,
      payload,
    });
    return updateCart({ cartItem: payload[feature_key] });
  };

  const deleteItemFromCart = (feature_key) => {
    const item = { ...cart[feature_key] };
    if (is_empty(item)) throw new Error("Item not in cart deleteItemFromCart");

    if (isProcessing(feature_key)) return;

    let payload;
    if (cart[feature_key].uuid) {
      payload = {
        ...cart,
        [feature_key]: {
          ...item,
          processing: true,
          change_state: change_states.deleted,
        },
      };
    }
    return updateCart({
      cartItem: payload[feature_key],
      updateRedux: false,
    }).then(() => {
      payload = { ...cart };
      delete payload[feature_key];
      dispatch({
        type: EXLY_ACCOUNT__SET_CART,
        payload,
      });
    });
  };

  const updateCart = async ({ cartItem, updateRedux = true }) => {
    const initialFeatureData = cart?.[cartItem?.feature_key];

    try {
      if (is_empty(cartItem?.feature_key))
        throw new Error("Invalid cartItem in updateCart");
      const payload = {
        item: cartItem,
      };

      const response = await dataProvider.custom_request(
        UPDATE_CART_API,
        apiMethods.POST,
        payload
      );
      if (!updateRedux) return;
      if (!is_empty(response?.data?.uuid)) {
        const reduxPayload = {
          [cartItem?.feature_key]: {
            ...initialFeatureData,
            ...cartItem,
            processing: false,
            uuid: response?.data?.uuid,
          },
        };
        dispatch({
          type: EXLY_ACCOUNT__UPDATE_CART,
          payload: reduxPayload,
        });
      }
    } catch (error) {
      const errMsg = error?.body?.message ?? "Something went wrong!";

      logError({
        error,
        occuredAt: "src/ui/pages/Account/hooks/useCart.js",
        when: "Error while updating cart",
        severity: "blocker",
      });

      notify(errMsg, notification_color_keys.error);

      // revert changes
      const reduxPayload = {
        [cartItem?.feature_key]: {
          ...initialFeatureData,
          processing: false,
        },
      };
      dispatch({
        type: EXLY_ACCOUNT__UPDATE_CART,
        payload: reduxPayload,
      });
    }
  };

  const handleGoToCart = ({ overrideCartEmptyCheck = false }) => {
    if (is_empty(getCartItemsCount()) && !overrideCartEmptyCheck)
      return notify("Cart is empty!", notification_color_keys.info);
    navigateTo(app_route_keys.cart);
  };

  return {
    addItemToCart,
    getItemQuantityInCart,
    removeItemFromCart,
    getCartItemsCount,
    deleteItemFromCart,
    handleGoToCart,
    isProcessing,
    updateCart,
    cartList: is_empty(cart)
      ? []
      : Object.values(cart).filter(
          (item) => item.change_state !== change_states.deleted
        ),
  };
};
