import React from "react";
import moduleStyle from "./subscriptionTransactions.module.css";
import LoupeIcon from "@material-ui/icons/Loupe";
import { ReactComponent as AutoFixHigh } from "assets/vectors/autoFixHigh.svg";

export const form_data = {
  name: {
    key: "title",
    label: "Item",
  },
  type: {
    key: "type",
    label: "Category",
  },
  date: {
    key: "order_date",
    label: "Transaction Date",
    start_date: { key: "start_date", label: "Start date" },
    end_date: { key: "end_date", label: "End date" },
  },
  status: {
    key: "status",
    label: "Status",
  },
  amount: {
    key: "amount",
    label: "Amount",
  },
  invoice_url: {
    key: "invoice_url",
    label: "Actions",
  },
  status_info_icon: {
    key: "status_info_icon",
  },
};

export const SUBSCRIPTION_TRANSACTIONS_STATUSES = {
  SUCCESS: 2,
  PENDING: 7,
  FAILED: 8,
  AUTO_DEBIT_ACTIVE: 20,
  AUTO_DEBIT_CANCELLED: 21,
};

export const transaction_statuses = {
  success: {
    value: SUBSCRIPTION_TRANSACTIONS_STATUSES.SUCCESS,
    label: "Success",
  },
  pending: {
    value: SUBSCRIPTION_TRANSACTIONS_STATUSES.PENDING,
    label: "Pending",
  },
  failed: {
    value: SUBSCRIPTION_TRANSACTIONS_STATUSES.FAILED,
    label: "Failed",
  },
  auto_debit_active: {
    value: SUBSCRIPTION_TRANSACTIONS_STATUSES.AUTO_DEBIT_ACTIVE,
    label: "Auto-Debit Active",
  },
  auto_debit_cancelled: {
    value: SUBSCRIPTION_TRANSACTIONS_STATUSES.AUTO_DEBIT_CANCELLED,
    label: "Auto-Debit Cancelled",
  },
};

export const TRANSACTION_STATUS_LABELS = {
  [SUBSCRIPTION_TRANSACTIONS_STATUSES.SUCCESS]: "Success",
  [SUBSCRIPTION_TRANSACTIONS_STATUSES.PENDING]: "Pending",
  [SUBSCRIPTION_TRANSACTIONS_STATUSES.FAILED]: "Failed",
  [SUBSCRIPTION_TRANSACTIONS_STATUSES.AUTO_DEBIT_ACTIVE]: "Auto-Debit Active",
  [SUBSCRIPTION_TRANSACTIONS_STATUSES.AUTO_DEBIT_CANCELLED]:
    "Auto-Debit Cancelled",
};

export const STATUS_CLASSES = {
  [SUBSCRIPTION_TRANSACTIONS_STATUSES.SUCCESS]: "tw-bg-[#00B779]",
  [SUBSCRIPTION_TRANSACTIONS_STATUSES.PENDING]: "tw-bg-[#BF2600]",
  [SUBSCRIPTION_TRANSACTIONS_STATUSES.FAILED]: "tw-bg-[#BF2600]",
  [SUBSCRIPTION_TRANSACTIONS_STATUSES.AUTO_DEBIT_ACTIVE]: "tw-bg-[#00B779]",
  [SUBSCRIPTION_TRANSACTIONS_STATUSES.AUTO_DEBIT_CANCELLED]: "tw-bg-[#BF2600]",
};

export const category_statuses = {
  plan_purchase: {
    label: "Plan purchase",
    value: 2,
    key: "Plan",
    icon: (
      <div className={`${moduleStyle.autoFixIcon} mr-2`}>
        <AutoFixHigh />
      </div>
    ),
  },
  add_on: {
    label: "Add-on purchase",
    value: 1,
    key: "Feature Credit",
    icon: (
      <div className={`${moduleStyle.loupeIcon} mr-2`}>
        <LoupeIcon />
      </div>
    ),
  },
};

export const emptyStateProps = {
  content_title: "Nothing here yet",
  empty_list_icon: `assets/images/Empty/search.png`,
  alt: "no transaction",
  children: `You can find records here after you’ve made a transaction`,
};
