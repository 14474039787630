import ExlyModal from "common/Components/ExlyModal";
import ExlyTable from "common/Components/ExlyTable";
import { Header } from "common/Components/Header";
import { app_route_keys } from "constants/urlPaths";
import { is_empty } from "features/Common/utils/common.utils";
import { ActionsColumn } from "features/MySubscriptions/modules/SubscriptionTransactions/modules/ActionsColumn/ActionsColumn";
import { AutoDebitCancellationModal } from "features/Transactions/modules/AutoDebitCancellationModal/AutoDebitCancellationModal";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import React from "react";
import { navigateTo } from "utils/urlUtils";
import { emptyStateProps } from "./subscriptionTransactions.constants";
import moduleStyle from "./subscriptionTransactions.module.css";
import {
  getFilterConfig,
  getSubscriptionTransactionsTableConfig,
  tabFilterConfig,
  tableChipsFormatter,
} from "./subscriptionTransactions.tableConfig";

export const SubscriptionTransactions = withComponentLibraryTheme((props) => {
  const [showMoreData, setShowMoreData] = React.useState(null);
  const [autoDebitCancellationModalState, setAutoDebitCancellationModalState] =
    React.useState({ open: false, record: null });

  const handleOpenAutoDebitCancellationModal = (record) => {
    setAutoDebitCancellationModalState({ open: true, record });
  };

  const handleCloseAutoDebitCancellationModal = () => {
    setAutoDebitCancellationModalState({ open: false, record: null });
  };

  return (
    <div className={`d-flex flex-column h-100`}>
      <Header
        onBackClick={() => navigateTo(app_route_keys.my_subscriptions)}
        title={"Transaction history"}
        classNames={{
          container: `tw-px-6 max-md:tw-px-4 tw-min-h-[75.23px] max-md:tw-min-h-0 max-md:tw-bg-[#FCFBFE] max-md:tw-shadow-[0px_7px_10px_0px_rgba(209,210,214,0.30)] max-md:!tw-border-none`,
        }}
      />
      <div className={`${moduleStyle.container} p-3 flex-grow-1`}>
        <ExlyTable
          ra_props={props}
          tableFilters={getFilterConfig()}
          tabFilterConfig={tabFilterConfig}
          tableChipsFormatter={tableChipsFormatter}
          columnConfig={getSubscriptionTransactionsTableConfig({
            setShowMoreData,
            onCancelAutoDebit: handleOpenAutoDebitCancellationModal,
          })}
          primaryKey="id"
          noExportButton={true}
          customPrimaryAction={{
            renderCustomAction: (record) => (
              <ActionsColumn
                record={record}
                onCancelAutoDebit={handleOpenAutoDebitCancellationModal}
              />
            ),
          }}
          borderedFields={true}
          fieldsAlignment="space-between"
          layoutProps={{
            showFixedBars: true,
            noShadow: true,
            paddingBottom: "32px",
            paddingMobile: "0px",
            noMobileBoxShadow: true,
          }}
          {...emptyStateProps}
        />
      </div>

      <ExlyModal
        onClose={() => setShowMoreData(null)}
        title={"Items in this purchase"}
        modal_props={{ modalPaperClassName: moduleStyle.dataModal }}
        open={!is_empty(showMoreData?.features)}
        customFooter={<></>}
      >
        <ul className="p-3">
          {showMoreData?.features?.map((item) => {
            return (
              <li key={item.name}>
                {item?.credits} {item?.name}
              </li>
            );
          })}
        </ul>
      </ExlyModal>

      {autoDebitCancellationModalState.open && (
        <AutoDebitCancellationModal
          onClose={handleCloseAutoDebitCancellationModal}
          {...autoDebitCancellationModalState}
        />
      )}
    </div>
  );
});
